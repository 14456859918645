<template>
	<header class="main-header">
		<div class="logo" @click="switchModule('category')">Weber Kleiderb&uuml;gel</div>
		<div class="introduction">
			<h2>Jetzt Ihren Kleiderbügel konfigurieren</h2>
			<p>Klick für Klick den eigenen Kleiderbügel individuell gestalten und kostenlos Anfragen.</p>
		</div>

		<!-- <a href="http://weber-hangers.com/hilfe/" target="_blank" class="btn-help">Hilfe</a> -->
		<a href="https://kleiderbuegel-shop.de/" target="_blank" class="btn-go-to-shop">zum Shop</a>
		<!-- <a href="http://weber-hangers.com/" target="_blank" class="btn-go-to-website"><svg version="1.1" id="menu" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 117.4 66.4" style="enable-background:new 0 0 117.4 66.4;" xml:space="preserve"><g><g><path class="line" d="M2,64.4c0,0,0.3,0,9.2,0S29.5,53.5,58,53.5s38.9,10.9,48.1,10.9s9.2,0,9.2,0"/></g><g><path class="line" d="M2,38.6c0,0,0.3,0,9.2,0S29.5,27.8,58,27.8s38.9,10.9,48.1,10.9s9.2,0,9.2,0"/></g><g><path class="line" d="M2,12.9c0,0,0.3,0,9.2,0S29.5,2,58,2s38.9,10.9,48.1,10.9s9.2,0,9.2,0"/></g></g></svg></a> -->
		<!-- <router-link to="/" tag="div">
      <button class="module hanger-item" @click="switchModule('hanger-item')">
        <i class="fal fa-search"></i>Bügel suchen
      </button>
    </router-link> -->
	</header>
</template>

<script>
export default {
	methods: {
		switchModule(module) {
			this.$store.commit('switchModule', module);
		},
	},
};
</script>

<style lang="scss" scoped>
.main-header {
	position: relative;
	padding: 1.5em 3% 0;
	display: flex;
	align-items: flex-start;
	margin: 0 auto;
	max-width: 1580px;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		flex-wrap: nowrap;
	}

	.logo {
		background: url(../assets/logo-weber-kleiderbuegel.svg) 0 0 no-repeat;
		background-size: contain;
		display: block;
		height: 100px;
		width: 275px;
		font-size: 0;
	}

	.introduction {
		padding: 3em 0.5em 0;

		order: 3;

		@media (min-width: 768px) {
			order: 0;
			padding-left: 1.5em;
			padding-right: 1.5em;
		}

		h2 {
			// font-size: 3.75rem;
			font-size: clamp(2rem, 3.4vw, 3.8rem);
			line-height: 1;
			color: var(--clr-green-400);
			font-weight: 600;
			letter-spacing: -0.0525em;
			margin: 0 0 0.2em;
		}

		p {
			font-size: 1.2rem;
			margin: 0;
		}
	}

	//.btn-help,
	.btn-go-to-shop {
		display: inline-block;
		font-size: 0.9rem;
		margin: 1em 0 0 auto;
		padding: 0.6em 1.5em 0.4em;
		border-radius: 100px;
		text-decoration: none;
		background: var(--clr-red-400);
		color: #fff;
		text-transform: uppercase;
		white-space: nowrap;

		@media (min-width: 768px) {
			margin-top: 0;
		}

		&:hover,
		&:focus {
			background: var(--clr-green-400);
			color: #fff;
		}
	}

	// .btn-help {
	// 	position: relative;
	// 	padding: 0.6em 1.2em 0.4em 1em;
	// 	margin-right: 0.5em;

	// 	&::before {
	// 		display: inline-block;
	// 		margin-right: 0.4em;
	// 		vertical-align: baseline;
	// 		font-style: normal;
	// 		text-rendering: auto;
	// 		-webkit-font-smoothing: antialiased;
	// 		font-family: 'Font Awesome 5 Pro';
	// 		font-weight: 600;
	// 		content: '';
	// 		font-variant: normal;
	// 	}
	// }

	.btn-go-to-website {
		display: inline-block;
		margin: 1.3rem 0 0;

		.line {
			fill: none;
			stroke: var(--clr-green-400);
			stroke-width: 6;
			stroke-linecap: square;
			stroke-miterlimit: 10;
		}

		svg {
			width: 2.5rem;
			height: 2.5rem;
		}

		&:hover,
		&:focus {
			background: var(--clr-green-400);
			color: #fff;
		}
	}

	// button.module.hanger-item {
	//   position: absolute;
	//   top: 10px;
	//   left: 10px;
	//   width: auto;
	//   height: auto;
	//   appearance: none;
	//   margin: 0;
	//   padding: 0.5em 0.7em;
	//   border: 0;
	//   border-radius: 10px;
	//   outline: 0;
	//   font-size: 1em;
	//   font-weight: 300;
	//   color: $text-color;
	//   background: transparent;
	//   cursor: pointer;
	//   transition: all 0.3s;

	//   @media (min-width: 576px) {
	//     top: 15px;
	//     left: 15px;
	//   }

	//   // Fontawesome Icon
	//   i {
	//     margin-right: 0.5em;
	//   }

	//   &:hover {
	//     background: $green-light;
	//   }
	// }
}
</style>
