<template>
	<div class="hanger-item-number">
		<span class="title">Ihre Auswahl</span>
		<div class="item-number">
			<span class="label">Artikelnummer</span>
			<span class="item-number" v-html="itemNumber"></span>
		</div>
		<div class="configuration">
			<ul>
				<li v-for="(item, index) in this.configurationResults" :key="index">
					<span class="label" v-html="item.label" v-if="item.label"></span>
					<span class="value" v-html="item.value" v-if="item.value"></span>
				</li>
			</ul>
		</div>
		<HangerButtonInquire :itemNumber="this.itemNumber" />
		<!-- <span class="number"> -->
		<!-- <span class="item">{{ item.itemNumber }} </span> -->
		<!-- <span class="equipment" title="Ausstattung">{{ item.equipment }} </span> -->
		<!-- <span class="material" title="Material">{{ material }} </span> -->
		<!-- <span class="color" title="Farbe">{{ item.color }}</span> -->
		<!-- <span class="hook" title="Haken">{{ hook }} </span> -->
		<!-- </span> -->
	</div>
</template>

<script>
import HangerButtonInquire from '@/components/hanger-configurator/ButtonInquire.vue';
export default {
	name: 'HangerConfiguratorItemNumber',
	components: {
		HangerButtonInquire,
	},
	// data: function () {
	// 	return {
	// 		configurationResults: Object,
	// 	};
	// },
	computed: {
		itemNumber() {
			let itemNumerString = '';
			let flagOrder = this.$store.state.settings['flag-order'];
			let currentHangerSelection = this.$store.state.currentHangerSelection;

			for (let flag of Object.entries(flagOrder)) {
				let flagSlug = flag[1];

				if (!currentHangerSelection[flagSlug]) continue;
				let propID = currentHangerSelection[flagSlug];

				if (!this.$store.state.currentCategory[flagSlug]) continue;
				if (!this.$store.state.currentCategory[flagSlug][propID]) continue;
				let currentFlagProp = this.$store.state.currentCategory[flagSlug][propID];

				if (currentFlagProp.flag) {
					itemNumerString += ` ${currentFlagProp.flag}`;
				}
			}

			return itemNumerString;
		},

		configurationResults() {
			let configurations = [];
			let flagOrder = this.$store.state.settings['flag-order'];
			let currentHangerSelection = this.$store.state.currentHangerSelection;

			for (let flag of Object.entries(flagOrder)) {
				let flagSlug = flag[1];
				let propID = currentHangerSelection[flagSlug];

				if (!this.$store.state.currentCategory[flagSlug]) continue;

				let currentFlagProp = this.$store.state.currentCategory[flagSlug][propID];
				if (currentFlagProp) {
					// Hide not active Attributes
					if (currentFlagProp.label) {
						if (currentFlagProp.label == 'Ohne') continue;
						if (currentFlagProp.label == 'Nein') continue;
					}

					let attribute = {
						label: '',
						value: '',
					};

					if (currentFlagProp.label) {
						attribute.value = currentFlagProp.label;
					} else {
						attribute.value = currentFlagProp;
					}

					let unit = this.$store.state.currentCategory[flagSlug + '-unit'];
					if (unit) attribute.value = attribute.value + ' ' + unit;

					let title = '';
					if (flagSlug == 'shapes') title = 'Form';
					if (flagSlug == 'lengths') title = 'Länge';
					if (flagSlug == 'heights') title = 'Höhe';
					if (flagSlug == 'thicknesses') title = 'Stärke';
					if (flagSlug == 'materials') title = 'Material';
					if (flagSlug == 'colors') title = 'Farbe';
					if (flagSlug == 'skirt-cuts') title = 'Rockeinschnitte';
					if (flagSlug == 'hooks') title = 'Haken';
					if (flagSlug == 'bars') title = 'Hosensteg';
					// if (flagSlug == 'clip-bars') title = 'Klammernsteg';
					if (flagSlug == 'ribbed-inlays') title = 'Rippeneinlage';
					if (flagSlug == 'non-slip-lacquers') title = 'Anti-Rutsch Lackierung';
					if (flagSlug == 'soft-touch-lacquers') title = 'Soft Touch Lackierung';
					if (flagSlug == 'flockings') title = 'Beflockung';
					if (title) {
						attribute.label = title;
					}

					configurations.push(attribute);
				}
			}

			return configurations;
		},
	},
	// methods: {},
	// watch: {
	// 	// item: function() {
	// 	//   this.setCurrentItemNumber()
	// 	// }
	// },
	// created() {
	//   this.setCurrentItemNumber()
	// }
};
</script>

<style lang="scss" scoped>
.hanger-item-number {
	grid-area: itemNumber;
	align-self: stretch;
	border-left: 10px solid var(--clr-beige-400);
	padding: 1rem;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-content: center;

	@media (min-width: 768px) {
		flex-wrap: wrap;
		flex-direction: row;
	}

	// display: grid;
	// grid-template-columns: auto 1fr;
	// grid-template-areas:
	// 	'title number'
	// 	'title configuration';
}

.title {
	// grid-area: title;
	margin-right: 1rem;
	font-size: 1.68rem;
	color: var(--clr-green-400);
}

.item-number {
	display: block;
	font-size: 1.4rem;
	margin-bottom: 1rem;

	.label {
		display: inline-block;
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0 0.5em 0.2rem 0;
		color: var(--clr-grey-300);
	}
}

.configuration {
	font-size: 1rem;
	line-height: 1.2;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		margin-bottom: 0.5rem;
		font-weight: 400;
	}

	.label {
		display: inline-block;
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-grey-300);
		margin: 0 0.5em 0.4rem 0;
		min-width: 4.3rem;
	}

	.value {
		display: inline-block;
	}

	// li:after {
	// 	content: '|';
	// 	margin: 0 0.4em;
	// }

	// li:last-child:after {
	// 	display: none;
	// }
}

// #hanger-configurator .hanger-item-number {
// 	display: block;
// 	text-align: center;
// 	// display: flex;
// 	// justify-content: center;
// 	// align-items: center;

// 	@media (min-width: 992px) {
// 		width: auto;
// 		order: 0;
// 	}

// 	.label {
// 		margin-left: 1em;
// 		margin-right: 1em;
// 		display: inline-block;
// 		vertical-align: middle;
// 		line-height: 2;
// 		font-size: 1.5rem;
// 		font-weight: 600;
// 		color: var(--clr-green-400);
// 	}

// 	.number {
// 		display: inline-block;
// 		vertical-align: middle;
// 		font-size: 1.2rem;
// 		color: var(--clr-black);
// 	}
// }
</style>
